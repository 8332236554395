import React from "react"

import Layout from "../components/layout"
import CryptoApp from "../components/CryptoApp"

export default function () {
  return (
    <Layout>
      <section id="crypto">
        <CryptoApp />
      </section>
    </Layout>
  )
}
